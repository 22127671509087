import React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import { v4 as uuidv4 } from "uuid"

import Layout from "../components/Layout"
import { makePostSchema } from "../utils/seoHelper"
import { createCrumbDataFromLocationPath } from "../utils/crumbHelper"

import LatestPosts from "../components/AcfPostBlocks/PostBody/LatestPosts"


const acfComponents = {
  PostHeader: loadable(() => import("../components/AcfPostBlocks/PostHeader")),
  PostBody: loadable(() => import("../components/AcfPostBlocks/PostBody")),
  PostContent: loadable(() => import("../components/AcfPostBlocks/SinglePOST")),
}

const Post = ({ location, data }) => {
  const {
    uri,
    postsLayouts: { postObject },
    seo: {
      fullHead,
      schema: { raw: schema },
    },
  } = data.wpPost

  const postSchema = makePostSchema(schema, uri, [postObject])
  const breadcrumbData = createCrumbDataFromLocationPath(location.pathname)

  const relatedPostsData = data.allWpPost.nodes
  const filteredRelatedPosts = relatedPostsData.filter(post => post.uri !== uri)
  const relatedPosts = filteredRelatedPosts.slice(0, 3)

  return (
    <Layout seoHeadTags={fullHead} schema={postSchema} location={location}>
      {/* Post Header */}

      {postObject.length > 0 &&
        postObject.map((block, i) => {
          if (!block) {
            console.warn(
              `Crikey! There was a problem rendering a block. It's value is ${block}.`
            )
            return null
          }

          const componentName = block.__typename.replace(
            /WpPost_Postslayouts_PostObject_/,
            ""
          )

          const Component = acfComponents[componentName]

          if (!Component) {
            console.warn(
              `Oh my! There was a problem rendering ${componentName}.`
            )
            return null
          }

          return (
            <Component
              key={uuidv4()}
              breadcrumbData={breadcrumbData}
              post={data.wpPost}
              {...block}
            />
          )
        })}
      <hr />
      {/* Post Footer */}
      <div className="d-flex flex-column align-items-center">
        <h2 className="text-center text-uppercase">Latest Articles</h2>
        <LatestPosts postsToRender={relatedPosts} trioWrap={true} />
      </div>
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query GET_POST($id: String!) {

     allWpPost(limit: 4) {
        nodes {
          ...PostDataQuery
        }
      }
      wpPost(id: { eq: $id }) {
        title
        uri
        date
        postsLayouts {
          postObject {
            __typename
            ...postHeaderFragment
            ...PostBodyFragment
            ...postContentFragment
          }
        }

        seo {
          fullHead
          schema {
            raw
          }
        }
      }

  }
`
