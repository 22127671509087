// extracted by mini-css-extract-plugin
export var articleCol = "LatestPosts-module--articleCol--5ae5e";
export var bgGrey100 = "LatestPosts-module--bg-grey-100--0592a";
export var bgGrey150 = "LatestPosts-module--bg-grey-150--6427a";
export var bgGrey200 = "LatestPosts-module--bg-grey-200--47bb1";
export var bgGrey300 = "LatestPosts-module--bg-grey-300--be31f";
export var bgGrey400 = "LatestPosts-module--bg-grey-400--1bca9";
export var bgGrey500 = "LatestPosts-module--bg-grey-500--77ca0";
export var bgGrey600 = "LatestPosts-module--bg-grey-600--d9be6";
export var bgGrey700 = "LatestPosts-module--bg-grey-700--d4755";
export var bgGrey800 = "LatestPosts-module--bg-grey-800--5b66d";
export var bgGrey900 = "LatestPosts-module--bg-grey-900--f1e4b";
export var card = "LatestPosts-module--card--cfa4e";
export var gatsbyImgDiv = "LatestPosts-module--gatsbyImgDiv--9576a";
export var postBlurb = "LatestPosts-module--postBlurb--eb9cf";
export var postTitle = "LatestPosts-module--postTitle--faff4";
export var textGrey100 = "LatestPosts-module--text-grey-100--0ec2d";
export var textGrey150 = "LatestPosts-module--text-grey-150--f1440";
export var textGrey200 = "LatestPosts-module--text-grey-200--90eb5";
export var textGrey300 = "LatestPosts-module--text-grey-300--0ea73";
export var textGrey400 = "LatestPosts-module--text-grey-400--db793";
export var textGrey500 = "LatestPosts-module--text-grey-500--e00fe";
export var textGrey600 = "LatestPosts-module--text-grey-600--0f224";
export var textGrey700 = "LatestPosts-module--text-grey-700--6d503";
export var textGrey800 = "LatestPosts-module--text-grey-800--ebc78";
export var textGrey900 = "LatestPosts-module--text-grey-900--85c04";